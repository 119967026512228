import styles from "./Policy.module.css";
function Policy() {
    const policy =[
        'ကျွန်ုပ်တို့သည် မိမိတို့ပြုမူဆက်ဆံစေလိုသည့်အတိုင်း အခြားသူများ၏ ဂုဏ်သိက္ခာကို လေးစားပြုမူပါမည်။',
        'ကျွန်ုပ်တို့သည် လူမျိုးတိုင်း၊ဘာသာတိုင်းအပေါ် ကိစ္စရပ်အားလုံးတွင် မျှတစွာပြုမူရပါမည်။',
        'ကျွန်ုပ်တို့၏ ဆောင်ရွက်မှုအားလုံးကို အချိန်တိုင်းတွင် ရိုးသားစွာနှင့် ယုံကြည်ထိုက်စွာဖြင့် ဆောင်ရွက်မည်။',
        'ကျွန်ုပ်တို့သည် သက်ဆိုင်ရာဒေသ၏ ယဉ်ကျေးမှု၊ဓလေ့ထုံးစံ(သို့)မူဝါဒများအပေါ်တွင် အလေးထားသည့် ဝတ်စားဆင်ယင်မှုပြုမည်။',
        'ကျွန်ုပ်တို့သည် အဖွဲ့၏ရန်ပုံငွေများ၊ ပိုင်ဆိုင်သည့်ပစ္စည်းဥစ္စာများကို သတိရှိစွာဖြင့် စီမံခန့်ခွဲဆောင်ရွက်မည်။',
        'ကျွန်ုပ်တို့သည် အခြားသူများအားခြိမ်းခြောက်ခြင်း(သို့) ထိခိုက်နစ်နာစေခြင်းတို့ကို မည်သည့်အခါမျှ ပြုလုပ်မည် မဟုတ်ပါ။',
        'ကျွန်ုပ်တို့သည်လိင်မှုဆိုင်ရာနှင့် အခြားသောအနိုင်အထက်ပြုကျင့်မှုများ အပါအဝင်ဥပဒေနှင့်မညီသော ဆောင်ရွက်မှုတစ်ရပ်ရပ်ကို မည်သည့်အခါမျှ ပြုလုပ်မည်မဟုတ်ပါ။',
        'ကျွန်ုပ်တို့၏ ကိုယ်ပိုင်မဟုတ်သော မည်သည့်ဥစ္စာပစ္စည်းများ၊ ရန်ပုံငွေများ(သို့) ပိုင်ဆိုင်ခွင့်များကို ခိုးယူခြင်း(သို့)အလွဲသုံးစားပြုခြင်း မပြုလုပ်ပါ။',
        'ကျွန်ုပ်တို့သည် မှတ်တမ်းမှတ်ရာများနှင့် ပတ်သက်ပြီး လိမ်ညာပြောဆိုခြင်း(သို့) ရည်ရွယ်ချက်ဖြင့် မှားယွင်းစေခြင်းတို့ကိုပြုလုပ်မည်မဟုတ်ပါ။',
        'ကျွန်ုပ်တို့သည် ပြည်သူလူထု၏ အစေခံများဖြစ်ကြသည်။(သို့သော် တည့်မတ်ထိမ်းကျောင်းပေးရမည်။)',
        'ကျွန်ုပ်တို့သည် အကူအညီပေးသူများနှင့် အကူအညီလိုအပ်နေသူများ နှစ်ဦးနှစ်ဘက်လုံးအတွက် ယုံကြည် စိတ်ချရသောတာဝန်ယူဆောင်ရွက်သူများအဖြစ် ရပ်တည်ပါမည်။',
        'ကျွန်ုပ်တို့၏ပံ့ပိုးကူညီမှုသည် နိုင်ငံရေးအရသော်လည်းကောင်း၊ ဘာသာရေးအရသော်လည်းကောင်း အမြတ်ထုတ်မှုမသက်ရောက်စေရပါ။',
        'ကျွန်ုပ်တို့၏ သတင်းအချက်အလက်များ ရေးသားထုတ်ဝေမှုနှင့် ကြော်ငြာကဏ္တတွင် အကူအညီလိုအပ်နေ သူများအား သနားစရာပုံစံမျိုးအဖြစ်မဟုတ်ဘဲ လူ့ဂုဏ်သိက္ခာနှင့်လျော်ညီစွာ ထည့်သွင်းဖော်ပြပါမည်။',
        'ကျွန်ုပ်တို့သည် တရားမဝင်အသုံးအဆောင်ပစ္စည်းများကိုအသုံးပြုခြင်း၊ လက်ဝယ်ပိုင်ဆိုင်ခြင်း ပြုမည်မဟုတ်ပါ။'
    ];
    return (
        <div className={styles.container}>
            <div className={styles.title}>ကျွန်ုပ်တို့၏ မူဝါဒများ</div>
            <div className={styles.textcontainer}>
                   <ul>
                    {policy.map((content,index) => (<li key={index}>{content}</li>))}  
                   </ul>
               </div>
        </div>
    );
}

export default Policy;
