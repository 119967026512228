import RelatedPosts from "../components/BlogDetails/RelatedPosts";
import Blog from "../components/BlogDetails/Blog";
import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getBlog } from "../data/data";
import { useState } from "react";

const BlogDetails = () => {
    const {id} = useParams();
    const [blog, setBlog] = useState({});
    const [relatedBlogs, setRelatedBlogs] = useState();

    useEffect( async ()=>{
      const {blog, relatedBlogs} = await getBlog(id);
      setBlog(blog);
      setRelatedBlogs(relatedBlogs);
    },[id])
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
    scrollToTop();
   
    return ( 
        <Fragment>
            <Blog blog={blog}/>
            <RelatedPosts relatedBlogs={relatedBlogs}/>
        </Fragment >
     );
}
 
export default BlogDetails;