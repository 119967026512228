import styles from './RelatedPosts.module.css';
import { Link } from "react-router-dom";
import { getReadableDateDisplay } from '../../utils/convertToHumanReadableTime';

const RelatedPosts = ({relatedBlogs}) => {
    let needBlock = new Array(3).fill("blog");
    return ( 
        <div className={styles.container}>
            <div className={styles.title}>Related Posts</div>
            <div className={styles.subcontainer}>
                {needBlock.map((value, index) => {
                    if (index >= (relatedBlogs?.length || 0)) {
                        return <div style={{flex:1}}></div>
                    } else {
                        let blog = relatedBlogs[index];
                        return <Link to={`/blogs/${blog.id}`} style={{textDecoration: 0, flex: 1}} key={index}>
                        <div className={styles.blogcontainer} >
                            <img
                                src={blog?.photoUrl}
                                alt={blog?.title}
                                className={styles.img}
                            />
                            <p className={styles.subtitle}>{blog?.title}</p>
                            <p className={styles.postingdate}>{getReadableDateDisplay(blog?.created_at)}</p>
                        </div>
                        </Link>
                    }
                })}
                
            </div>
        </div>
     );
}
 
export default RelatedPosts;