import React, { Fragment } from 'react'
import Cover from '../components/Home/Cover'
import FeaturedBlogs from '../components/Home/FeaturedBlogs';
import Motto from '../components/Home/Motto';
import FeaturedGallery from '../components/Home/FeaturedGallery';
import VisionMissionGoal from '../components/Home/VisionMissionGoal';
import FeaturedVideo from '../components/Home/FeaturedVideo';
import { getFeaturedBlogs, getFeaturedPhotos, getFeaturedVideos } from "../data/data";
import { useEffect } from 'react';
import { useState } from 'react';

const Home = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
    scrollToTop();
   
    const [featuredBlogs, setFeaturedBlogs] = useState([]);
    const [featuredPhotos, setFeaturedPhotos] = useState([]);
    const [featuredVideos, setFeaturedVideos] = useState([]);
    useEffect( ()=> {
      const fetchData = async ()=>{
        const blogs = await getFeaturedBlogs();
        setFeaturedBlogs(blogs);
        const photos = await getFeaturedPhotos();
        setFeaturedPhotos(photos);
        const videos = await getFeaturedVideos();
        setFeaturedVideos(videos);
      }
      fetchData();
    },[])
    console.log("featured Videos:", featuredVideos)

    return (
        <Fragment>
            <Cover />
            <Motto/>
            <FeaturedBlogs featuredblogs={featuredBlogs}/>
            <FeaturedGallery featuredPhotos={featuredPhotos}/>
            <FeaturedVideo  featuredVideos={featuredVideos}/>
            <VisionMissionGoal/>
        </Fragment >
     );
}
export default Home;